import { BaseAPI } from "@utils/base-api";
import {
  ICapiGenericEventRequestDto,
  ICapiUserData,
  CapiServerEventName,
  ICapiCustomData,
  CAPI_EVENT_ENDPOINT,
} from "@capi/types";

export class CapiClient extends BaseAPI {

  constructor(
    baseURL: string,
    accessToken: string | undefined,
  ) {
    super(
      baseURL,
      accessToken,
      {
        withCredentials: true,
      },
    );
  }

  public async pageview(
    currentUrl: string,
    accessToken: string | undefined,
    userData?: ICapiUserData,
    testEventCode?: string,
  ) {
    const data: ICapiGenericEventRequestDto = {
      type: CapiServerEventName.PAGEVIEW,
      eventSourceUrl: currentUrl,
      actionSource: "website",
      testEventCode: testEventCode?.trim(),
      userData,
    };
    try {
      await this.axios.post(
        CAPI_EVENT_ENDPOINT,
        data,
        {
          headers: accessToken ? {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          } : {
            "Content-Type": "application/json",
          },
        },
      );
    } catch (e) {
      console.error("Failed to send pageview event", e);
    }
  }

  public async initiateCheckout(
    currentUrl: string,
    accessToken: string | undefined,
    eventId: string,
    capiCustomData: ICapiCustomData | undefined,
    userData: ICapiUserData,
    testEventCode?: string,
  ) {
    const data: ICapiGenericEventRequestDto = {
      type: CapiServerEventName.INITIATE_CHECKOUT,
      eventSourceUrl: currentUrl,
      actionSource: "website",
      testEventCode: testEventCode?.trim(),
      userData,
      eventId,
      customData: capiCustomData,
    };
    try {
      await this.axios.post(
        CAPI_EVENT_ENDPOINT,
        data,
        {
          headers: accessToken ? {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          } : {
            "Content-Type": "application/json",
          },
        },
      );
    } catch (e) {
      console.error("Failed to send initiateCheckout event", e);
    }
  }

}
