import { Module, Global } from "@nestjs/common";

import { PrimaryModelsProvider } from "./provider";

@Global()
@Module({
  providers: [ PrimaryModelsProvider ],
  exports: [ PrimaryModelsProvider ],
})
export class PrimaryModelsModule {}
