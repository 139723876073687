// @ts-nocheck
/* eslint-disable no-console */

/**
* Given a prisma client, enable logging for queries slower than minDuration
*/
export function enableQueryLogging(client, minDuration = 0) {
  client.$on("query", (e) => {
    if (e.duration > minDuration) {
      console.log(`Query: ${e.query}`);
      console.log(`Params: ${e.params}`);
      console.log(`Duration: ${e.duration}ms`);
    }
  });
}
