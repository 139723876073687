import { AxiosResponse } from "axios";

import type { LoginSuccessResponse } from "@auth/types";
import { BaseAPI } from "@utils/base-api";

import { PublicConfig } from "../config/public";

// APIs related to the fresh meal plans specifically
export class FreshMealPlanAPI extends BaseAPI {

  public async getCustomerFirstOrder(customerId: string, flow?: string): Promise<AxiosResponse<OrderData, unknown>> {
    return this.axios.get(
      "/customer/getOrder",
      { params: {
        katkinCustomerId: customerId,
        flow,
        force: PublicConfig.ANIKIN_ENVIRONMENT === "dev" || PublicConfig.ANIKIN_ENVIRONMENT === "stg" || PublicConfig.ANIKIN_ENVIRONMENT === "ftr",
      } },
    );
  }

}

export type ChargeData = {
  shipping_address?: {
    address1?: string;
    address2?: string;
    city?: string;
    company?: string;
    country?: string;
    first_name?: string;
    last_name?: string;
    phone?: string;
    province?: string;
    zip?: string;
  };
  email: string;
  id: number;
  processed_at_instant: string;
  created_at_instant: string;
};

export type OrderData = {
  charge: ChargeData,
  authData?: LoginSuccessResponse,
  gtmData?: {
    kktransactionId: string;
  },
  gtmDataGA4?: {
    event: "purchase"
    ecommerce: {
      transaction_id: string;
    }
  },
  shopifyId?: number,
  customer_hash?: string,
  price_no_vat: string,
  catNames?: string,
  // In YYYY-MM-DD format
  deliveryDate: string,
  discountCode?: string,
  fullName: string,
  firstName: string,
  lastName: string,
};
