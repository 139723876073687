import { AxiosError } from "axios";

import {
  FieldName,
  IActionRequestTrackingInfo,
  IActionResultWithBody,
  KatKinProduct,
  SubmitFieldData,
} from "@forms/schema";
import { BaseAPI } from "@utils/base-api";
import {
  SubmissionFieldValues,
} from "libs/state/src/lib/stores/useFormServiceStore";

// Makes this compatible with console,
// so that you can use it as a fallback if datadog is not available
interface DDLogger {
  debug(message: string, messageContext?: object, error?: Error): void
  info(message: string, messageContext?: object, error?: Error): void
  warn(message: string, messageContext?: object, error?: Error): void
  error(message: string, messageContext?: object, error?: Error): void
}

export class FormsActionsAPI extends BaseAPI {

  public async performAction<T = never>(
    actionName: string,
    actionKeyValues: SubmissionFieldValues,
    product: KatKinProduct,
    formUsed: string | undefined,
    linkingId: string | undefined,
    featureFlags: { [key: string]: string | boolean } | undefined,
    trackingInfo: IActionRequestTrackingInfo | undefined,
    logger: DDLogger,
  ): Promise<IActionResultWithBody<T>> {
    const values = convertMapToRecord(actionKeyValues);
    const logContext = {
      actionName,
      actionKeyValues,
      product,
      formUsed,
      linkingId,
      featureFlags,
      trackingInfo,
      values,
    };
    try {
      logger.info(`Submitting ${actionName} action`, logContext);
      const result = await this.axios.post<IActionResultWithBody<T>>("actions", {
        name: actionName,
        values,
        product,
        formUsed,
        linkingId,
        featureFlags,
        trackingInfo,
      });
      if (!result.data.success) {
        logger.error(`Action ${actionName} failed`, { ...logContext, result });
        return {
          ...result.data,
          success: false,
        };
      }
      logger.info(`Action ${actionName} succeeded`, { ...logContext, result });
      return result.data;
    } catch (error) {
      logger.error(`Action ${actionName} got exception`, { ...logContext, error }, error as Error);
      if (typeof error === "object" && error instanceof AxiosError) {
        if (typeof error.response?.data === "object" && error && error?.response && error?.response?.data) {
          if ("message" in error.response.data && "failedOn" in error.response.data && "fieldName" in error.response.data.failedOn) {
            logger.error("FormValidationError occurred.", error);
            // should set the error here?
            return {
              success: false,
              message: error.message?.toString(),
              failedOn: {
                fieldName: error.response.data.failedOn.fieldName,
              },
            };
          }
          if ("message" in error.response.data && typeof error.response.data.message === "object" && "body" in error.response.data.message) {
            // If there's a body in the message, return the body
            logger.error("Error occurred.", { error: JSON.parse(error.response.data.message.body) });
            return {
              success: false,
              message: error.response.data.message.body,
            };
          }
          if ("message" in error.response.data && typeof error.response.data.message === "string") {
            logger.error("Error occurred.", { error: error.response.data.message });
            return {
              success: false,
              message: error.response.data.message,
            };
          }
          return {
            success: false,
            message: error.message?.toString(),
          };
        }

        return {
          success: false,
          message: error.message,
        };
      }
      return {
        success: false,
        message: "An error occurred",
      };
    }
  }

}
export const convertMapToRecord = (
  mapToConvert: Map<FieldName, SubmitFieldData | SubmitFieldData[]>,
): Partial<Record<FieldName, SubmitFieldData | SubmitFieldData[]>> => {
  const newObject: Partial<Record<FieldName, SubmitFieldData | SubmitFieldData[]>> = {
  };
  for (const [ key, value ] of mapToConvert) {
    newObject[key] = value;
  }
  return newObject;
};
