import { AxiosResponse } from "axios";

import { SubmissionFieldValues } from "@./state";
import { FieldName, SubmitFieldData } from "@forms/schema";
import { BaseAPI } from "@utils/base-api";
import { TSurvey } from "libs/forms/src/lib/constants/surveys.constants";

export class SurveyAnswers extends BaseAPI {

  public async storeSurveyAnswers(
    surveyKeyValues: SubmissionFieldValues,
    formUsed: string,
    linkingId?: string,
  ) {
    const values = convertMapToRecord(surveyKeyValues);
    try {
      await this.axios.post("answers", {
        values,
        formUsed,
        linkingId,
      });
    } catch (error) {
      console.error("An error occurred when storing answers to the survey", error);
    }
  }

  public async getCompleteSurveys(): Promise<AxiosResponse<TSurvey[]>> {
    try {
      return await this.axios.get("/surveys/complete");
    } catch (error) {
      console.error("An error fetching complete surveys", error);
      throw Error(`An error fetching complete surveys: ${error}`);
    }
  }

}

export const convertMapToRecord = (
  mapToConvert: Map<FieldName, SubmitFieldData | SubmitFieldData[]>,
): Partial<Record<FieldName, SubmitFieldData | SubmitFieldData[]>> => {
  const newObject: Partial<Record<FieldName, SubmitFieldData | SubmitFieldData[]>> = {
  };
  for (const [ key, value ] of mapToConvert) {
    newObject[key] = value;
  }
  return newObject;
};
