import { PrismaClient } from "../generated";

// Extend to rename and allow NestJS DI to differentiate between this
// and other Prisma clients (e.g. factory, old database)
export class PrimaryModelsClient extends PrismaClient {

  public async healthcheck() {
    await this.$queryRaw`SELECT 1;`;
  }

}
