import { PrimaryModelsClient } from "./client";
import { enableQueryLogging } from "./query-logger";

const LOG_PRISMA_QUERIES = process.env.LOG_PRISMA_QUERIES === "true";

export const PrimaryModelsProvider = {
  provide: PrimaryModelsClient,
  useFactory: async () => {

    const client = LOG_PRISMA_QUERIES ? new PrimaryModelsClient({
      log: [
        {
          emit: "event",
          level: "query",
        },
      ],
    }) : new PrimaryModelsClient();

    if (LOG_PRISMA_QUERIES) {
      enableQueryLogging(client, 0);
    }

    await client.$connect();
    return client;
  },
};
