import { Prisma } from "../generated";
import type { Sql as PrismaSql } from "../generated/runtime/library";

import { PrimaryModelsClient } from "./client";

export * from "../generated";

// Note: Export type alias is because direct type export
// causes issues with building next apps
export type Sql = PrismaSql;

export type PrimaryModelsTransaction = Omit<
PrimaryModelsClient,
"$on" | "$disconnect" | "$use" | "$connect" | "$transaction" | "$extends" | "healthcheck"
>;

export const { sql } = Prisma;
export * from "./client";
export * from "./module";
