import { BaseAPI } from "@utils/base-api";
import { IDiscountApplicationCustomerInfo } from "@discounts/types";
import { AxiosResponse } from "axios";

export interface IUpcomingDiscount {
  name: string;
  expiresAt: string;
}

export class DiscountsAPI extends BaseAPI {

  public async getQueuedDiscounts(): Promise<AxiosResponse<IDiscountApplicationCustomerInfo[]>> {
    try {
      return await this.axios.get(
        "/applications/customer?filter=POTENTIALLY USABLE",
      );
    } catch (error) {
      throw new Error();
    }
  }

}
