import { BaseAPI } from "@utils/base-api";

export interface StatisticsResult {
  mealsServed: number;
  foodSavedGrams: number;
  lessSmellyPoops: number;
  moneySavedPounds: number;
  moreMeatGrams: number;
  lessFillerGrams: number;
  totalCatParents: number;
}

export class Statistics extends BaseAPI {

  public async global(): Promise<StatisticsResult> {
    const result = await this.axios.get("global");
    return result.data;
  }

  public async personal(): Promise<StatisticsResult> {
    const result = await this.axios.get("personal");
    return result.data;
  }

}
