export function fbcFromFbclidQueryParam(
  inputUrl: string | undefined,
  logger: (message: string, exception: unknown) => void,
): {
    fbc: string,
    fbclidQuery: string,
    parsedUrl: URL,
  } | undefined {
  if (!inputUrl?.trim()) return undefined;
  try {
    const parsedUrl = new URL(inputUrl);
    const fbclidQuery = parsedUrl.searchParams
      .getAll("fbclid")
      .find((it) => it.trim())
      ?.trim();
    if (!fbclidQuery) return undefined;
    return {
      fbc: `fb.1.${Date.now()}.${fbclidQuery}`,
      fbclidQuery,
      parsedUrl,
    };
  } catch (e) {
    logger("fbcFromFbclidQueryParam: failed to parse url", e);
    return undefined;
  }
}
